<template>
  <div>
    <h2 v-html="$t('search_milk_quality_period_title')"></h2>
    <!-- Periode von -->
    <DbmMonthPicker
      name="search_milk_quality_period_period_from"
      id="search_milk_quality_period_period_from"
      ref="searchQualityFrom"
      v-model="SearchMilkQualityPeriodStore.Quality_From"
      :dateLabel="$t('search_milk_quality_period_period_from')"
      :rules="validFromRule"
      :clearable="true"
    />
    <!-- Periode bis -->
    <DbmMonthPicker
      name="search_milk_quality_period_period_until"
      id="search_milk_quality_period_period_until"
      v-model="SearchMilkQualityPeriodStore.Quality_Until"
      :dateLabel="$t('search_milk_quality_period_period_until')"
      :rules="validUntilRule"
      :clearable="true"
      returnType="lastOfMonth"
    />
    <div class="link align-right" @click="expanded = !expanded">
      <span v-if="!expanded" v-html="$t('more')"></span>
      <span v-else v-html="$t('less')"></span>
    </div>
    <div v-if="expanded">
      <!-- expanded here -->
      <!-- Probenahme von -->
      <DbmDatePicker
        name="search_milk_quality_period_sample_from"
        id="search_milk_quality_period_sample_from"
        v-model="SearchMilkQualityPeriodStore.Quality_DateFrom"
        :dateLabel="$t('search_milk_quality_period_sample_from')"
        :clearable="true"
      />
      <!-- Probenahme bis -->
      <DbmDatePicker
        name="search_milk_quality_period_sample_until"
        id="search_milk_quality_period_sample_until"
        v-model="SearchMilkQualityPeriodStore.Quality_DateUntil"
        :dateLabel="$t('search_milk_quality_period_sample_until')"
        :clearable="true"
      />
      <!-- Jahr -->
      <v-select
        id="search_milk_quality_period_year"
        v-model="SearchMilkQualityPeriodStore.Quality_Year"
        :items="availableYears"
        item-title="text"
        item-value="value"
        :label="$t('search_milk_quality_period_year')"
        clearable
        @keyup.enter="search"
      />
      <!-- Monat-->
      <v-select
        id="search_milk_quality_period_month"
        v-model="SearchMilkQualityPeriodStore.Quality_Month"
        :items="monthsOfTheYear"
        item-title="text"
        item-value="value"
        :label="$t('search_milk_quality_period_month')"
        clearable
        @keyup.enter="search"
      />
    </div>
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import DbmMonthPicker from '@/components/dbmMonthPicker.vue'
import DbmDatePicker from '@/components/dbmDatePicker.vue'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchMilkQualityPeriod',
  extends: baseSearchVue,
  props: {
    forDifference: { type: Boolean, default: false, required: false },
    validCustomRule: {
      type: Array,
      default: () => {
        return []
      },
      required: false
    }
  },
  data() {
    return {
      expanded: false,
      monthsOfTheYear: [
        { value: 1, text: this.$t('search_milk_quality_period_january') },
        { value: 2, text: this.$t('search_milk_quality_period_february') },
        { value: 3, text: this.$t('search_milk_quality_period_march') },
        { value: 4, text: this.$t('search_milk_quality_period_april') },
        { value: 5, text: this.$t('search_milk_quality_period_may') },
        { value: 6, text: this.$t('search_milk_quality_period_june') },
        { value: 7, text: this.$t('search_milk_quality_period_july') },
        { value: 8, text: this.$t('search_milk_quality_period_august') },
        { value: 9, text: this.$t('search_milk_quality_period_september') },
        { value: 10, text: this.$t('search_milk_quality_period_october') },
        { value: 11, text: this.$t('search_milk_quality_period_november') },
        { value: 12, text: this.$t('search_milk_quality_period_december') }
      ],
      availableYears: []
    }
  },
  computed: {
    SearchMilkQualityPeriodStore() {
      return this.$store.state.SearchMilkQualityPeriodStore
    },
    validFromRule() {
      return [
        ...[this.$rules.isValidMonthRange(this.SearchMilkQualityPeriodStore.Quality_From, this.SearchMilkQualityPeriodStore.Quality_Until, 12)],
        ...this.validCustomRule
      ]
    },
    validUntilRule() {
      return [
        ...[this.$rules.isValidMonthRange(this.SearchMilkQualityPeriodStore.Quality_From, this.SearchMilkQualityPeriodStore.Quality_Until, 12)],
        ...this.validCustomRule
      ]
    }
  },
  methods: {
    reset() {
      this.SearchMilkQualityPeriodStore.Quality_Year = null
      this.SearchMilkQualityPeriodStore.Quality_Month = null
      this.SearchMilkQualityPeriodStore.Quality_DateFrom = null
      this.resetFields(this.SearchMilkQualityPeriodStore)
    },
    fillAvailableYears() {
      const currentDate = new Date()
      var currentYear = currentDate.getFullYear()
      while (currentYear > 2010) {
        this.availableYears.push({
          value: currentYear,
          text: currentYear.toString()
        })
        currentYear--
      }
    }
  },
  components: {
    DbmMonthPicker: DbmMonthPicker,
    DbmDatePicker: DbmDatePicker
  },
  mounted() {
    this.fillAvailableYears()
    this.initSearchStoreFromQuery(this.SearchMilkQualityPeriodStore, this.$route.query)
  }
})
</script>
